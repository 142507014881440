import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import Arrow from '../assets/icons/arrow-next.svg';
import Layout from '../components/Layout';
import Builder from '../components/Builder';
import Seo from '../components/Seo';
import NotFound from '../components/NotFound';

const Program = () => {
  const data = useStaticQuery(graphql`
    query ProgramQuery {
      programs: allWpProgram {
        nodes {
          program {
            callToActionUrl
            cta
            pageVisibility
          }
          builder {
            contentEn {
              imagesPosition
              text
              images {
                localFile {
                  url
                }
              }
            }
            contentFr {
              imagesPosition
              text
              images {
                localFile {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const { locale } = intl;

  if (
    !data?.programs?.nodes ||
    data?.programs?.nodes.length === 0 ||
    (data?.programs?.nodes.length > 0 &&
      !data?.programs?.nodes[0]?.program?.pageVisibility)
  ) {
    return (
      <NotFound />
    );
  }

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'program.title' })} />
      <div className="page-title">
        {intl.formatMessage({ id: 'program.title' })}
      </div>
      <div className="page-title-underline" />
      <Builder
        sections={
          locale === 'en' &&
          data?.programs?.nodes.length > 0 &&
          data?.programs?.nodes[0]?.builder?.contentEn?.length > 0
            ? data.programs.nodes[0].builder.contentEn
            : data?.programs?.nodes[0]?.builder?.contentFr
        }
      />
      {data.programs.nodes[0].program?.cta && (
        <a
          className="program-cta-button"
          href={data.programs.nodes[0].program?.callToActionUrl || '#'}
          target="_blank"
          rel="noreferrer"
        >
          {intl.formatMessage({ id: 'program.cta' })}
          <Arrow />
        </a>
      )}
    </Layout>
  );
};

export default Program;
